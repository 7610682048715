<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            label="단위공정"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="HCF_FACILITY_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="hcfFacilityTypeCd"
            label="시설유형"
            v-model="searchParam.hcfFacilityTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="시설 목록"
      tableId="facility"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="addFacility" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazard-facility',
  data() {
    return {
      searchParam: {
        plantCd: null,
        processCd: null,
        hcfFacilityTypeCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '단위공정',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'facilityName',
            field: 'facilityName',
            label: '시설명(차량번호)',
            align: 'left',
            style: 'width:250px',
            type: 'link',
            sortable: false,
          },
          {
            name: 'facilityNo',
            field: 'facilityNo',
            label: '시설관리번호',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'hcfFacilityTypeName',
            field: 'hcfFacilityTypeName',
            label: '시설유형',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'inspection',
            field: 'hcfInspectionCycleName',
            label: '정기검사',
            child: [
              {
                name: 'hcfInspectionCycleName',
                field: 'hcfInspectionCycleName',
                label: '주기',
                align: 'center',
                style: 'width:100px',
                sortable: false,
              },
              {
                name: 'lastRemunerationDate',
                field: 'lastRemunerationDate',
                label: '최종검사일',
                align: 'center',
                style: 'width:90px',
                sortable: false,
              },
              {
                name: 'nextInspectionDate',
                field: 'nextInspectionDate',
                label: '차기검사일',
                align: 'center',
                style: 'width:90px',
                sortable: false,
              },
            ]
          },
          {
            name: 'selfCheck',
            field: 'hcfInspectionCycleName',
            label: '자체점검',
            child: [
              {
                name: 'lastSelfCheckDate',
                field: 'lastSelfCheckDate',
                label: '최종자체점검일',
                align: 'center',
                style: 'width:90px',
                sortable: false,
              },
              {
                name: 'nextSelfCheckDate',
                field: 'nextSelfCheckDate',
                label: '차기자체점검일',
                align: 'center',
                style: 'width:90px',
                sortable: false,
              },
            ]
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
      },
      useFlagItems: [],
      popupOptions: {
        isFull: true,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.env.facility.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    addFacility() {
      this.linkClick();
    },
    linkClick(row, col, index) {
      this.popupOptions.title = '시설 상세'; 
      this.popupOptions.param = {
        facilityCd: row ? row.facilityCd : '',
      };
      this.popupOptions.target = () => import(`${'./hazardFacilityDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
